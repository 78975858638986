/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { ELocalStorage } from 'app/shared/models/localstorage.model';
import { AuthUtils } from '../auth/auth.utils';

export const menuNavigation: FuseNavigationItem[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/home',
    hidden: () => desabilitaMenu('HOME'),
  },
  {
    id: 'administracao',
    title: 'Administração',
    type: 'collapsable',
    icon: 'heroicons_outline:cog',
    hidden: () => desabilitaMenu('MENU_ADMINISTRACAO'),
    children: [
      {
        id: 'perfil',
        title: 'Perfil',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/perfil',
        hidden: () => desabilitaMenu('PERFIL'),
      },
      {
        id: 'permissao',
        title: 'Permissão',
        type: 'basic',
        icon: 'mat_outline:lock',
        link: '/permissao',
        hidden: () => desabilitaMenu('PERMISSAO'),
      },
      {
        id: 'perfil-permissao',
        title: 'Perfil/Permissão',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/perfil-permissao',
        hidden: () => desabilitaMenu('PERFIL_PERMISSAO'),
      },
      {
        id: 'usuario',
        title: 'Usuário',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/usuario',
        hidden: () => desabilitaMenu('USUARIO'),
      },
      {
        id: 'log',
        title: 'Log',
        type: 'collapsable',
        icon: 'heroicons_outline:document-text',
        hidden: () => desabilitaMenu('LOG'),
        children: [
          {
            id: 'log.acesso',
            title: 'Acesso',
            type: 'basic',
            icon: 'heroicons_outline:user-circle',
            link: '/log/acesso',
            hidden: () => desabilitaMenu('PESQUISAR_LOG_ACESSO'),
          },
          {
            id: 'log.historico',
            title: 'Histórico',
            type: 'basic',
            icon: 'heroicons_outline:user-circle',
            link: '/log/historico',
            hidden: () => desabilitaMenu('PESQUISAR_LOG_HISTORICO'),
          },
        ],
      },
    ],
  },
];

export const adminNavigation: FuseNavigationItem[] = [
  {
    id: 'admin-home',
    title: 'Admin',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];

export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/home',
    hidden: () => desabilitaMenu('HOME'),
  },
  {
    id: 'administracao',
    title: 'Administração',
    type: 'aside',
    icon: 'heroicons_outline:cog',
    hidden: () => desabilitaMenu('MENU_ADMINISTRACAO'),
    children: [
      {
        id: 'perfil',
        title: 'Perfil',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/perfil',
        hidden: () => desabilitaMenu('PERFIL'),
      },
      {
        id: 'permissao',
        title: 'Permissão',
        type: 'basic',
        icon: 'mat_outline:lock',
        link: '/permissao',
        hidden: () => desabilitaMenu('PERMISSAO'),
      },
      {
        id: 'perfil-permissao',
        title: 'Perfil/Permissão',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/perfil-permissao',
        hidden: () => desabilitaMenu('PERFIL_PERMISSAO'),
      },
      {
        id: 'usuario',
        title: 'Usuário',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/usuario',
        hidden: () => desabilitaMenu('USUARIO'),
      },
      {
        id: 'log',
        title: 'Log',
        type: 'collapsable',
        icon: 'heroicons_outline:document-text',
        hidden: () => desabilitaMenu('LOG'),
        children: [
          {
            id: 'log.acesso',
            title: 'Acesso',
            type: 'basic',
            icon: 'heroicons_outline:user-circle',
            link: '/log/acesso',
            hidden: () => desabilitaMenu('PESQUISAR_LOG_ACESSO'),
          },
          {
            id: 'log.historico',
            title: 'Histórico',
            type: 'basic',
            icon: 'heroicons_outline:user-circle',
            link: '/log/historico',
            hidden: () => desabilitaMenu('PESQUISAR_LOG_HISTORICO'),
          },
        ],
      },
    ],
  },
];

function desabilitaMenu(permissao: string): boolean {
  const token = localStorage.getItem(ELocalStorage.TOKEN);
  if (!token) {
    return true;
  }

  const { ROLES } = AuthUtils.decodeToken(token);
  if (Object(ROLES).find((p) => p == permissao)) {
    return false;
  }

  return true;
}
