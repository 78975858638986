<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div>
      <div class="flex items-center h-20 p-6 pb-0 mb-4">
      <img class="w-10 mr-2" src="assets/images/logo/logo.svg" alt="Logo image" />
        <div>
          <label class="text-3xl font-bold">{{ nomeSistema }}</label>
          <div class="flex-auto border-t border-gray-600 max-w-80"></div>
          <div class="flex flex-col">
            Base de Sistemas
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Navigation footer hook -->
  <ng-container fuseVerticalNavigationContentFooter>
    <div class="fixed left-0 bottom-0 w-64  text-center p-5 ml-3 bg-accent-900 border-t text-sm opacity-40">
      <p> Desenvolvido por: <span>Mardonio S Costa </span></p>
      <strong>Developer Front Sr</strong>
      <p> versão: {{ version }}</p>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      <!-- <search [appearance]="'bar'"></search> -->
      <!-- <shortcuts></shortcuts> -->
      <!-- <notifications></notifications> -->
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

</div>
