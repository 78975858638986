import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { NotFoundDataComponent } from './components/not-found-data/not-found-data.component';
import { CpfPipe } from './pipes/cpf.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FuseCardModule } from '@fuse/components/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  exports: [
    // Modulos
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Alguma material
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSidenavModule,
    MatExpansionModule,

    // FuseModule
    FuseCardModule,

    // Componentes
    FormFieldErrorComponent,
    NotFoundDataComponent,
    PageHeaderComponent,

    // PIPES
    CpfPipe,
  ],
  declarations: [
    // Componentes
    FormFieldErrorComponent,
    NotFoundDataComponent,
    PageHeaderComponent,

    // PIPES
    CpfPipe,
  ],
  providers: [],
})
export class SharedModule {}
